/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-props-no-spreading */
import 'ui/scss/grid/grid.css';
import 'ui/scss/app.scss';
import 'utils/modernizr';
import { PlaylistTypes } from 'const';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import {
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom';

/* Pages */
import {
  PageNotFound,
  LandingPage,
  MySubscriptions,
  PremiumPurchase,
  TeamsActivity,
  AllActivity,
  AllContent,
  EditData,
  Signup,
  AllPlaylists,
  AllFiveMinutes,
  CollectionDetails,
  ExternalLogin,
  Notes,
  Homepage,
  Search
} from 'pages';
import ResetPassword from 'pages/LandingPage/sections/ResetPassword';
import ChangePassword from 'pages/LandingPage/sections/ChangePassword';
import Access from 'pages/LandingPage/sections/Access';
import Catalog from 'pages/LandingPage/sections/Catalog';
import EditCards from 'pages/Profile/sections/EditCards';
import { CONFIG_TEXT_GET } from 'redux/actions/configuration';
import ConfirmPassword from 'pages/LandingPage/sections/ConfirmPassword';
import { AUTH_LOGIN, LOADER_CLOSE, LOADER_OPEN } from 'redux/actions';
import LastPayments from 'pages/Profile/sections/LastPayments';
import ChoosePortal from 'pages/LandingPage/sections/ChoosePortal';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import SuccessModal from 'ui/components/Modals/SuccessModal';
import { isManager, isSimpleUser } from 'utils/roles';
import {
  DeleteSubscriptionInterface,
  SubscriptionsConfigSuccessInterface,
  WelcomeInterface,
} from 'interface/configuration';
import { GlobalLayout, Loader, InviteFriendModal } from 'ui/components';
import { ModalsPortal } from 'ui/components/Modals';
import routes from 'routes';
import theme from 'ui/theme';
import {
  CreateUser,
  DrawProfile,
  ConfirmCreateUser,
  CreatePassword,
} from 'pages/OnBoarding';
import ManagerProfile from 'pages/Profile/sections/ManagerProfile';
import UserProfile from 'pages/Profile/sections/UserProfile';
import CompleteManager from 'pages/OnBoarding/CompleteManager';
import ConfirmChangedPassword from 'pages/LandingPage/sections/ConfirmChangedPassword';
import SetFirstPassword from 'pages/Signup/steps/SetFirstPassword';
import SetFirstPasswordFromBank from 'pages/Signup/steps/SetFirstPasswordFromBank';
import LockedDetail from 'pages/LockedDetail';
import { GET_DOMAIN, GET_FILTERED_DOMAIN_SETTINGS } from 'redux/actions/domain';
import { GET_BOOKMARKS } from 'redux/actions/social';
import FollowProfileUser from 'pages/Profile/sections/FollowProfileUser';
import FollowProfileManager from 'pages/Profile/sections/FollowProfileManager';
import AllCourses from 'pages/ViewAll/AllCourses';
import { getToken, StorageName } from 'utils/token';
import SessionExpired from 'pages/SessionExpired';
import Sidebar from 'ui/components/Sidebar';
import PricedCatalog from 'pages/LandingPage/sections/PricedCatalog';
import SetFiscalCode from 'pages/SetFiscalCode';
import Maintenance from 'pages/Maintenance';
import PackageDetails from 'pages/PackageDetails';
import EventDetails from 'pages/EventDetails';
import {channel} from "./api/config";
import {AUTHENTICATION_SSO_SIGNIN} from "./redux/actions/authentication";

const App = ({
  isLogged,
  sendDataTreatment,
  checkAuthentication,
  getConfigText,
  clientEnabled,
  getBookmarks,
  getDomain,
  getFilteredDomainSetting,
  domain,
  loaderOpen,
  loaderClose,
  authenticationSignin,
  authenticationSSOSignin,
}) => {
  useEffect(() => {
    if (!domain?.id) {
      getDomain();
    } else {
      checkAuthentication();

      const params = location.search.slice(1, location.search.length).split('&');
      let code;
      if (!isLogged && params[0]?.length > 1) {
        code = params.map(str => ({
          key: str.split('=')[0],
          value: str.split('=')[1]
        }));
        if(code && code[0].key === 'code'){
          authenticationSSOSignin({
            channel,
            code: code[0].value,
            domain: domain?.id
          });
        }

      }
      getConfigText({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain.id]);

  useEffect(() => {
    !domain?.domain_settings && getFilteredDomainSetting();
  }, [domain.domain_settings, getFilteredDomainSetting])

  const location = useLocation();

  useEffect(() => {
    isLogged && getBookmarks();
  }, [getBookmarks, isLogged]);

  const shouldPutFence = false; /* SWITCH TO TRUE FOR MANINTENANCE MODE */

  const initIsAllowed = shouldPutFence ? !!localStorage.getItem(StorageName.Maintenance) : true;
  const [isAllowed, setIsAllowed] = useState(initIsAllowed);
  useEffect(() => {
    if (!shouldPutFence) return;
    function handleStorage() {
      setIsAllowed(!!localStorage.getItem(StorageName.maintenance));
    }
    window.addEventListener('storage', handleStorage);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('storage', handleStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (shouldPutFence && !isAllowed) {
    const queryParams = new URLSearchParams(window.location.search);
    const hasToken = queryParams.has(StorageName.Maintenance);
    isAllowed !== hasToken && setIsAllowed(hasToken);
    hasToken && localStorage.setItem(StorageName.Maintenance, 'staff_only');
  }


  const { token } = getToken() || {};
  
  const defaultColor = '#e2e2e2';

  loaderClose();
  //console.log(Boolean(token), isLogged, clientEnabled);
  if (token && !isLogged && sendDataTreatment === false) {
    loaderOpen();
    return (
      <ThemeProvider theme={theme(domain?.brand?.color || defaultColor)}>
        <Loader isSpinning />
      </ThemeProvider>
    );
  }

  const userRoutes = [
    <Route
      key="homepage"
      path={routes.homepage.path}
      render={props => <Homepage {...props} title={routes.homepage.title} />}
    />,
    <Route
      key="search"
      path={routes.search.path}
      render={props => <Search {...props} title={routes.search.title} />}
    />,
    <Route
      key="all-content"
      path={`${routes.allContent.path}/:type`}
      render={props => (
        <AllContent {...props} title={routes.allContent.title} />
      )}
    />,
    <Route
      key="notes"
      path={[
        routes.notes.path,
        `${routes.notes.path}/:noteId`
      ]}
      exact
      render={props => <Notes {...props} title={routes.notes.title} />}
    />,
    <Route
      key="package-details"
      path={[
        `${routes.package.path}/:id`
      ]}
      exact
      render={props => <PackageDetails {...props} />}
    />,
    <Route
      key="collection-details"
      path={[
        `${routes.detail.path}/:type/:id`,
        `${routes.detail.path}/:type/:id/:itemId`
      ]}
      exact
      render={props => <CollectionDetails {...props} />}
    />,
    <Route
      key="event-details"
      path={[
        `${routes.event.path}/:id`
      ]}
      exact
      render={props => <EventDetails {...props} />}
    />,
    <Route
      key="locked-details"
      path={`${routes.lockedDetails.path}/:id`}
      exact
      render={props => <LockedDetail {...props} />}
    />,
    <Route
      key="all-five-minutes"
      path={routes.allFiveMinutes.path}
      exact
      render={props => (
        <AllFiveMinutes {...props} title={routes.allFiveMinutes.title} />
      )}
    />,
    <Route
      key="all-playlists"
      path={routes.allPlaylists.path}
      exact
      render={props => (
        <AllPlaylists {...props} title={routes.allPlaylists.title} />
      )}
    />,
    <Route
      key="all-playlists-categories"
      path={routes.allPlaylistsCategories.path}
      exact
      render={props => (
        <AllPlaylists {...props} title={routes.allPlaylistsCategories.title} type={PlaylistTypes.CATEGORIES}/>
      )}
    />,
    <Route
      key="all-playlists-selections"
      path={routes.allPlaylistsSelections.path}
      exact
      render={props => (
        <AllPlaylists {...props} title={routes.allPlaylistsSelections.title} type={PlaylistTypes.SELECTIONS}/>
      )}
    />,
    <Route
      key="all-playlists-collections"
      path={routes.allPlaylistsCollections.path}
      exact
      render={props => (
        <AllPlaylists {...props} title={routes.allPlaylistsCollections.title} type={PlaylistTypes.COLLECTIONS}/>
      )}
    />,
    <Route
      key="all-playlists-recommended-collections"
      path={routes.allPlaylistsRecommendedCollections.path}
      exact
      render={props => (
        <AllPlaylists {...props} title={routes.allPlaylistsRecommendedCollections.title} type={PlaylistTypes.RECOMMENDED_COLLECTIONS}/>
      )}
    />,
    <Route
      key="all-playlists-dr-sim"
      path={routes.allPlaylistsDrSim.path}
      exact
      render={props => (
        <AllPlaylists {...props} title={routes.allPlaylistsDrSim.title} type={PlaylistTypes.DR_SIM}/>
      )}
    />,
    <Route
      key="all-playlists-promoted"
      path={routes.allPlaylistsPromoted.path}
      exact
      render={props => (
        <AllPlaylists {...props} title={routes.allPlaylistsPromoted.title} type={PlaylistTypes.PROMOTED}/>
      )}
    />,
    <Route
      key="all-playlists-skillroom"
      path={routes.allPlaylistsSkillroom.path}
      exact
      render={props => (
        <AllPlaylists {...props} title={routes.allPlaylistsSkillroom.title} type={PlaylistTypes.SKILLROOM}/>
      )}
    />,
    <Route
        key="all-playlists-corsi-ecm"
        path={routes.allPlaylistsCorsiEcm.path}
        exact
        render={props => (
            <AllPlaylists {...props} title={routes.allPlaylistsCorsiEcm.title} type={PlaylistTypes.CORSI_ECM}/>
        )}
    />,
    <Route
      key="all-playlists-expert"
      path={routes.allPlaylistsExpert.path}
      exact
      render={props => (
        <AllPlaylists {...props} title={routes.allPlaylistsExpert.title} type={PlaylistTypes.EXPERT}/>
      )}
    />,
    <Route
      key="all-playlists-courses"
      path={routes.allPlaylistsCourses.path}
      exact
      render={props => (
        <AllPlaylists {...props} title={routes.allPlaylistsCourses.title} type={PlaylistTypes.COURSES}/>
      )}
    />,
    <Route
      key="all-courses"
      path={routes.allCourses.path}
      exact
      render={props => (
        <AllCourses {...props} title={routes.allCourses.title} />
      )}
    />,
    <Route
      key="draw-profile"
      path={routes.drawProfile.path}
      exact
      render={props => (
        <DrawProfile {...props} title={routes.drawProfile.title} />
      )}
    />,
    <Route
      key="confirm-create-user"
      path={routes.confirmCreateUser.path}
      exact
      render={props => (
        <ConfirmCreateUser {...props} title={routes.confirmCreateUser.title} />
      )}
    />,
    <Route
      key="user-profile"
      path={routes.userProfile.path}
      exact
      render={props => (
        <UserProfile {...props} title={routes.userProfile.title} />
      )}
    />,
    <Route
      key="follow-profile-user"
      path={`${routes.FollowProfileUser.path}/:userId`}
      exact
      render={props => (
        <FollowProfileUser {...props} title={routes.FollowProfileUser.title} />
      )}
    />,
  ];

  const managerRoutes = [
    <Route
      key="manager-profile"
      path={routes.managerProfile.path}
      exact
      render={props => (
        <ManagerProfile {...props} title={routes.managerProfile.title} />
      )}
    />,
    /*
    Nascosto per richiesta di nascondere "Segui il team in navbar e relative pagine" (29/11/2023)
    <Route
      key="teams-activity"
      path={routes.teamsActivity.path}
      exact
      render={props => (
        <TeamsActivity {...props} title={routes.teamsActivity.title} />
      )}
    />,
    <Route
      key="all-activity"
      path={routes.allActivity.path}
      render={props => (
        <AllActivity {...props} title={routes.allActivity.title} />
      )}
    />,
    <Route
      key="all-content"
      path={`${routes.allContent.path}/:type`}
      render={props => (
        <AllContent {...props} title={routes.allContent.title} />
      )}
    />,*/
    <Route
      key="edit-data"
      path={routes.editData.path}
      exact
      render={props => (
        <EditData {...props} title={routes.editData.title} />
      )}
    />,
    <Route
      key="edit-cards"
      path={routes.editCards.path}
      exact
      render={props => (
        <EditCards {...props} title={routes.editCards.title} />
      )}
    />,
    <Route
      key="last-payments"
      path={routes.lastPayments.path}
      exact
      render={props => (
        <LastPayments {...props} title={routes.lastPayments.title} />
      )}
    />,
    <Route
      key="premium-purchase"
      path={routes.premiumPurchase.path}
      render={props => (
        <PremiumPurchase {...props} title={routes.premiumPurchase.title} />
      )}
    />,
    <Route
      key="signup"
      path={routes.signup.path}
      render={props => <Signup {...props} title={routes.signup.title} />}
    />,

    <Route
      key="change-password"
      path={routes.changePassword.path}
      exact
      render={props => (
        <ChangePassword {...props} title={routes.changePassword.title} />
      )}
    />,
    <Route
      key="confirm-password"
      path={routes.userProfile.path}
      exact
      render={props => (
        <ConfirmPassword {...props} title={routes.confirmPassword.title} />
      )}
    />,
    <Route
      key="my-subscriptions"
      path={routes.mySubscription.path}
      render={props => (
        <MySubscriptions {...props} title={routes.mySubscription.title} />
      )}
    />,
    <Route
      key="follow-profile-manager"
      path={`${routes.followProfileManager.path}/:userId`}
      exact
      render={props => (
        <FollowProfileManager
          {...props}
          title={routes.followProfileManager.title}
        />
      )}
    />,
  ];

  return (
    <ThemeProvider theme={theme(domain?.brand?.color || defaultColor)}>
      <>
        {!isAllowed ? (
          <Route
            render={props => (
              <Maintenance {...props} title={routes.maintenance.title} />
            )}
          />
        ) : isLogged ? (
          <GlobalLayout>
            <Switch>
              <Route
                path={routes.compileManagerProfile.path}
                exact
                render={props => (
                  <CompleteManager
                    {...props}
                    title={routes.compileManagerProfile.title}
                  />
                )}
              />
              <Route
                path={routes.choosePortal.path}
                exact
                render={props => (
                  <ChoosePortal {...props} title={routes.choosePortal.title} />
                )}
              />
              {isSimpleUser(clientEnabled) && userRoutes}
              {isManager(clientEnabled) && managerRoutes}

              <Route
                path={routes.changePassword.path}
                exact
                render={props => (
                  <ChangePassword
                    {...props}
                    title={routes.changePassword.title}
                  />
                )}
              />
              <Route
                path={routes.confirmPassword.path}
                exact
                render={props => (
                  <ConfirmPassword
                    {...props}
                    title={routes.confirmPassword.title}
                  />
                )}
              />
              <Route
                path={routes.setFirstPasswordFromBank.path}
                exact
                render={props => (
                  <SetFirstPasswordFromBank
                    {...props}
                    title={routes.setFirstPassword.title}
                  />
                )}
              />
              <Route
                path={routes.notFound.path}
                render={props => (
                  <PageNotFound {...props} title={routes.notFound.title} />
                )}
              />
              <Route
                path={routes.sessionExpired.path}
                render={props => (
                  <SessionExpired
                    {...props}
                    title={routes.sessionExpired.title}
                  />
                )}
              />
              <Route
                path={routes.setFiscalCode.path}
                render={props => (
                  <SetFiscalCode
                    {...props}
                    title={routes.setFiscalCode.title}
                  />
                )}
              />
              <Redirect
                to={
                  !isManager(clientEnabled)
                    ? routes.homepage.path
                    : routes.mySubscription.path
                }
              />
            </Switch>
            <SuccessModal />
            <InviteFriendModal />
            <Sidebar />
          </GlobalLayout>
        ) : (
          <Switch>
            <Route
              path={routes.signup.path}
              render={props => (
                <Signup {...props} title={routes.signup.title} />
              )}
            />
            <Route
              path={routes.externalLogin.path}
              render={props =>(
                <ExternalLogin {...props}/>
              )}
            />
            <Route
              path={routes.access.path}
              exact
              render={props => (
                <Access {...props} title={routes.access.title} />
              )}
            />
            <Route
              path={routes.resetPassword.path}
              exact
              render={props => (
                <ResetPassword {...props} title={routes.resetPassword.title} />
              )}
            />
            <Route
              path={routes.changePassword.path}
              exact
              render={props => (
                <ChangePassword
                  {...props}
                  title={routes.changePassword.title}
                />
              )}
            />
            <Route
              path={routes.confirmPassword.path}
              exact
              render={props => (
                <ConfirmPassword
                  {...props}
                  title={routes.confirmPassword.title}
                />
              )}
            />
            <Route
              path={routes.confirmChangedPassword.path}
              exact
              render={props => (
                <ConfirmChangedPassword
                  {...props}
                  title={routes.confirmChangedPassword.title}
                />
              )}
            />
            <Route
              path={routes.compileManagerProfile.path}
              exact
              render={props => (
                <CompleteManager
                  {...props}
                  title={routes.compileManagerProfile.title}
                />
              )}
            />
            <Route
              path={routes.compileProfile.path}
              exact
              render={props => (
                <CreateUser {...props} title={routes.compileProfile.title} />
              )}
            />
            <Route
              path={routes.createUser.path}
              exact
              render={props => (
                <CreatePassword {...props} title={routes.createUser.title} />
              )}
            />
            <Route
              path={routes.setFirstPassword.path}
              exact
              render={props => (
                <SetFirstPassword
                  {...props}
                  title={routes.setFirstPassword.title}
                />
              )}
            />
            <Route
              path={routes.setFirstPasswordFromBank.path}
              exact
              render={props => (
                <SetFirstPasswordFromBank
                  {...props}
                  title={routes.setFirstPassword.title}
                />
              )}
            />
            <Route
              path={routes.pricedCatalog.path}
              exact
              render={props => (
                <PricedCatalog {...props} title={routes.pricedCatalog.title} />
              )}
            />
            <Route
              path={routes.catalog.path}
              exact
              render={props => (
                <Catalog {...props} title={routes.catalog.title} />
              )}
            />

            <Route
              path={routes.landingPage.path}
              exact
              render={props => (
                <LandingPage {...props} title={routes.landingPage.title} />
              )}
            />
            <Route
              path={routes.sessionExpired.path}
              render={props => (
                <SessionExpired
                  {...props}
                  title={routes.sessionExpired.title}
                />
              )}
            />

            <Redirect to={routes.landingPage.path} />
          </Switch>
        )}
        <Loader />
        <ModalsPortal.Target />
        <ModalErrors />
      </>
    </ThemeProvider>
  );
};

App.propTypes = {
  // HOC (connect, state)
  isLogged: PropTypes.bool.isRequired,
  sendDataTreatment: PropTypes.bool,
  subscriptions_config_success: SubscriptionsConfigSuccessInterface,
  delete_subscription: DeleteSubscriptionInterface,
  welcome: WelcomeInterface,
  getConfigText: PropTypes.func.isRequired,
  checkAuthentication: PropTypes.func.isRequired,
  themeColor: PropTypes.string,
  clientEnabled: PropTypes.array,
  getBookmarks: PropTypes.func,
  getDomain: PropTypes.func.isRequired,
  getFilteredDomainSetting: PropTypes.func.isRequired,
  domain: PropTypes.object,
  loaderOpen: PropTypes.func,
  loaderClose: PropTypes.func,
  authenticationSSOSignin: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isLogged: state.app.authState.isLogged,
    subscriptions_config_success: state.configuration,
    delete_subscription: state.configuration,
    welcome: state.configuration,
    clientEnabled: state.authentication.user.clientEnabled,
    sendDataTreatment: state.authentication.user.sendDataTreatment,
    domain: state.domain,
    router: state.router,
  }),
  dispatch => ({
    authenticationSSOSignin: payload =>
        dispatch({ type: AUTHENTICATION_SSO_SIGNIN._REQUEST, payload }),
    // authenticationSignin: payload =>
    //   dispatch({ type: AUTHENTICATION_SIGNIN._REQUEST, payload }),
    getBookmarks: () => dispatch({ type: GET_BOOKMARKS._REQUEST }),
    getConfigText: () => dispatch({ type: CONFIG_TEXT_GET._REQUEST }),
    checkAuthentication: () => dispatch({ type: AUTH_LOGIN._REQUEST }),
    getDomain: () => dispatch({ type: GET_DOMAIN._REQUEST }),
    getFilteredDomainSetting: () =>
      dispatch({ type: GET_FILTERED_DOMAIN_SETTINGS._REQUEST }),
    loaderOpen: () => dispatch({ type: LOADER_OPEN }),
    loaderClose: () => dispatch({ type: LOADER_CLOSE }),
  })
)(App);
