import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  font-family: 'ProDisplay', sans-serif;
`;

export const ModalGlobalContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  border-radius: 6px;
  pointer-events: auto;
  position: relative;
  user-select: none;

  min-height: ${props => (props.height && props.height === 'auto' ? `${props.height}` : '459px')};
  padding: 26px;
  padding-top: 0;

  height: ${props => (props.height ? `${props.height}` : '90vw')};
  width: ${props => (props.width ? `${props.width}` : '80vw')};
  max-height: 600px;
  max-width: 1024px;
  margin: 0 auto;

  @media ${props => props.theme.device.tablet} {
    width: ${props => props.widthtablet && `${props.widthtablet}px`};
  }

  @media ${props => props.theme.device.mobile} {
    position: fixed;
    height: 100vh;
    max-height: -webkit-fill-available;
    width: 100vw;
    top: 0;
    left: 0;
    border-radius: 0;
    padding: 15px 20px 15px 30px;
  }
`;

export const ModalBody = styled.div`
    color: #333333;
  padding-top: 20px;
  overflow: auto;
  height: calc(100% - 80px);
`;
export const Header = styled.div`
    border-bottom: 1px solid #125C87;
    height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon-play{
    background: #cdcdcd;
  }
  .info{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #5d5d5d;
    font-size: 15px;
    flex-basis: 200px;
      .duration{
        display: inline-flex;
        align-items: center;
      }
    .numberOfAsset{
      border-left: 1px solid #5d5d5d;
      padding-left: 10px;
      margin-left: 10px;
      display: inline-flex;
    }
  }
`;

export const Title = styled.div`
  color: #000;
  margin-bottom: 0;
  font-size: 20px;
  width: 372px;
  font-weight: bold;
  flex: auto;
  
`;

export const BtnContainer = styled.div`
  width: 177px;

  &:not(:last-child) {
    margin-right: 30px;
  }
`;

export const Icon = styled.span`
  font-size: 30px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
  flex-grow: 0;
`;
