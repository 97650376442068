import React from 'react';
import PropTypes from 'prop-types';

import useBookmarks from 'customHooks/useBookmarks';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import TYPES from 'ui/typography/pro.types';
import PlaceholderImg from 'ui/assets/img/S4C_THUMBNAIL.png';
import { detailType } from 'utils/common';
import { getDatesDay, getDatesHour} from 'utils/date-format';
import {
  // clockToTime,
  secsToTime
} from 'utils/duration-format';
import LogoAlfaFCM from 'ui/assets/img/alfa_fcm_logo.png';

import { connect } from 'react-redux';
import {
  CourseCard,
  CourseLabel,
  CourseTitle,
  CoursePlay,
  EventCard,
  EventHeader,
  ColorLabel,
  EventFooter,
  IconAssets,
  IconBookmark,
  IconLock,
  NewsCard,
  NewsImage,
  NewsLabel,
  NewsFooter,
  NewsTitle,
  NewsPlayL,
  NewsPlayS,
  PackageCard,
  PackageContents,
  PackageFooter,
  PackageHeader,
  PackageImage,
  PackageProgress, IconInfo
} from './style';
import colors from '../../theme/partials/colors';
import { Image } from '../Modals/PromoModal/style';
import LogoAlphaFcm from '../../atoms/LogoAlphaFCM/LogoAlphaFCM';

const ContentCard = ({
  type,
  data,
  onClick,
  ellipsis,
  homepage,
  currentViewport,
  viewportTypes,
   themeColor,
  openModalInfo,
  items
}) => {
  const { handleBookmark, isBookmarked } = useBookmarks();
  const cardIsBookmarked = isBookmarked(data);

  const getDuration = duration => `${Math.round(duration / 60)} min`;

  const getContentByType = typeContent => {
    const typeContentLC = typeContent.toLowerCase();
    switch (typeContentLC) {
      case detailType.NEWS:
      case detailType.RELATED_COURSE:
        return (
          <NewsCard onClick={onClick}>
            <NewsImage src={data.cover ?? PlaceholderImg} />
            <NewsFooter>
              {data.format === 'video' && (
                <NewsPlayL className="icon-icona-play" />
              )}
              <NewsTitle>
                <ProText
                  type={TYPES.TEXT.NEWS_CARD_TITLE}
                  currentViewport={currentViewport}
                  ellipsis={ellipsis}
                  tooltip={data.title}
                >
                  {data.title}
                </ProText>
              </NewsTitle>
              <NewsLabel>
                {data.type === detailType.RELATED_COURSE && (
                  <NewsPlayS className="icon-play" />
                )}
                <ProText
                  type={data.type === detailType.RELATED_COURSE
                    ? TYPES.TEXT.NEWS_CARD_TITLE
                    : TYPES.TEXT.NEWS_CARD_LABEL
                  }
                  currentViewport={currentViewport}
                >
                  {data.type === detailType.RELATED_COURSE
                    ? getDuration(data.duration)
                    : 'News'}
                </ProText>
              </NewsLabel>
            </NewsFooter>
          </NewsCard>
        );
      case detailType.COURSE:
        return (
          <CourseCard onClick={onClick} bg={data.cover ?? PlaceholderImg}>
            <CourseTitle>
              <ProDisplay
                type={TYPES.DISPLAY.COURSE_CARD_TITLE}
                currentViewport={currentViewport}
                ellipsis={2}
                tooltip={data.title}
              >
                {data.title}
              </ProDisplay>
            </CourseTitle>
            <CourseLabel>
              <CoursePlay className="icon-play" />
              <ProDisplay
                type={TYPES.DISPLAY.COURSE_CARD_LABEL}
                currentViewport={currentViewport}
              >
                {getDuration(data.duration)}
              </ProDisplay>
            </CourseLabel>
          </CourseCard>
        );
      case detailType.PACKAGE:
        const progress = data.numberOfAssetContentsCompleted / data.numberOfAssetContents;
        return (
          <PackageCard onClick={onClick}>
            <PackageHeader>
              <div
                className="icon-container"
              >
                {data.body && openModalInfo && <IconInfo className="icon-info" onClick={(event) => {
                  event.stopPropagation();
                  openModalInfo(data, typeContentLC);
                  }}
                />}
                <PackageImage src={data.cover ?? PlaceholderImg}/>
                <ProText
                  type={TYPES.TEXT.PACKAGE_CARD_TITLE}
                  currentViewport={currentViewport}
                  ellipsis={
                    (viewportTypes[currentViewport] < viewportTypes.mediaIsLaptop) ? 1 :2
                  }
                >
                  { data.title }
                  {data.packageType?.toLowerCase() === 'premium' ?
                    <span className="premium">
                      Premium
                      <span className="icon-premium" />
                    </span>
                    : ''}
                </ProText>
              </div>
              <PackageContents>
                <IconAssets className="icon-collection align-left" />
                <ProText
                  type={TYPES.TEXT.PACKAGE_CARD_CONTENTS}
                  currentViewport={currentViewport}
                >
                  <span style={{color: '#125C87'}}>{ data.numberOfAsset } Moduli</span>
                </ProText>
                    {/* items contiene i soli pacchetti ecm. Visualizzo il logo solo per pacchetti ecm */}
                {items && items.some(item => item.itemId == data.itemId) && (
                  <Image src={LogoAlfaFCM} style={{ width: '100px', height: '45px', marginLeft: 'auto' }} />
                )}
              </PackageContents>
            </PackageHeader>
            <PackageFooter>
              <ProText
                type={TYPES.TEXT.PACKAGE_CARD_FOOTER}
                currentViewport={currentViewport}
              >
                Durata: <span className='bold'>{ secsToTime(data.totalDuration) }</span>
              </ProText>
              <PackageProgress progress={ progress }>
                <ProText
                  type={TYPES.TEXT.PACKAGE_CARD_FOOTER}
                  currentViewport={currentViewport}
                >
                  Avanzamento: <span className='bold'>{
                  data.numberOfAssetContentsCompleted ?
                      Math.round((data.numberOfAssetContentsCompleted / data.numberOfAssetContents) * 100)
                      : 0}%</span>
                </ProText>
                <span className="bar bg">
                  <span className="bar fg" />
                </span>
              </PackageProgress>
            </PackageFooter>
          </PackageCard>
        )
      case detailType.LIVE_EVENT:
      case detailType.PLAYLIST:
      case detailType.COLLECTION:
      case detailType.SELECTION:
        const isPlaylist = (type) =>
          [detailType.COLLECTION, detailType.PLAYLIST, detailType.SELECTION]
            .includes(type)
        const getLabelConfig = labelType => {
          switch(labelType) {
            case 'virtual':
              return { bgColor:  colors(themeColor).virtualBlue, label: 'Virtual Classroom' }
            case 'live':
              return { bgColor:  colors(themeColor).primary.base, label: 'Residenziale' }
            case 'collection':
            case 'selection':
            case 'playlist':
              return { bgColor:  colors(themeColor).azureDark, label: 'E-learning' }
            default:
          }
        };

        const getColorLabel = (type, count) => {
          const config = getLabelConfig(type)
          return config ? (
            <ColorLabel bgColor={config.bgColor} large={!count && count !== 0}>
              <ProText
                type={TYPES.TEXT.EVENT_LABEL_HOMEPAGE}
                currentViewport={currentViewport}
              >{count ?? config.label}</ProText>
            </ColorLabel>
          ) : null;
        }
        return (
          <EventCard homepage={homepage} bg={data.cover ?? PlaceholderImg} onClick={onClick}>
            <EventHeader homepage={homepage}>
              {getColorLabel(isPlaylist(data.type.toLowerCase())
                ? data.type
                : data.session?.sessionType?.toLowerCase()
              )}
              {data.locked && !data.ecmFlag && !data.flagEcm ? (
                <IconLock className="icon-icona-lucchetto" />
              ) : null}
              {((typeContentLC === detailType.LIVE_EVENT) || (typeContentLC === detailType.COLLECTION) || (typeContentLC === detailType.SELECTION)) && data.body && openModalInfo && <IconInfo className="icon-info" onClick={(event) => {
                event.stopPropagation();
                openModalInfo(data, typeContentLC);
                }}
              />}
              <IconBookmark
                className={
                  cardIsBookmarked
                    ? 'icon-icona-salva-attivo selected'
                    : 'icon-icona-salva-active '
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleBookmark(data);
                }}
            />
            </EventHeader>
            <EventFooter homepage={homepage}>
              {(data.weight || data.weight === 0) ? (
                getColorLabel(
                  isPlaylist(data.type.toLowerCase())
                    ? data.type
                    : data.session?.sessionType?.toLowerCase(),
                  data.weight
                )
              ) : null}
              <ProText
                type={TYPES.TEXT.EVENT_TITLE_HOMEPAGE}
                ellipsis={ellipsis}
                tooltip={data.title}
                currentViewport={currentViewport}
              >
                {data.title}
              </ProText>
              {typeContent.toLowerCase() === detailType.LIVE_EVENT && data.session ? (
               <>
                 <ProText
                  type={TYPES.TEXT.EVENT_SESSION_HOMEPAGE}
                  currentViewport={currentViewport}
                >
                  <span className='capitalize'>{getDatesDay(data.session)}</span>
                </ProText>
                <ProText
                  type={TYPES.TEXT.EVENT_SESSION_HOMEPAGE}
                  currentViewport={currentViewport}
                >
                  <span className='capitalize'>{getDatesHour(data.session)}</span>
                </ProText>
               </>
              ) : null}
              {isPlaylist(typeContentLC) && data.totalDuration ? (
                <ProText
                  type={TYPES.TEXT.EVENT_SESSION_HOMEPAGE}
                  currentViewport={currentViewport}
                >
                  Durata: <b>{secsToTime(data.totalDurationSec)}</b>
                </ProText>
              ) : null}
              {isPlaylist(typeContentLC) ? (
                <ProText
                  type={TYPES.TEXT.EVENT_SESSION_HOMEPAGE}
                  currentViewport={currentViewport}
                >
                  Avanzamento: <b>{data.numberOfContentsCompleted ?
                    Math.round((data.numberOfContentsCompleted / data.numberOfContents) * 100)
                    : 0}%</b>
                </ProText>
              ) : null}
            </EventFooter>
          </EventCard>
        );
      default:
        return null;
    }
  };

  return (
    getContentByType(type)
  );
};

ContentCard.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  ellipsis: PropTypes.number,
  index: PropTypes.number,
  onClick: PropTypes.func,
  //HOC (connect, dispatch)
  getPackageAssetDescription: PropTypes.func

  //HOC withMediaQueries
};

ContentCard.defaultProps = {
  ellipsis: 3,
  index: 1
};

export default withMediaQueries(
    connect(
        state => {
          const {
            brand: { color: themeColor }
          } = state.domain;
      const { items } = state.playlist.corsiEcmPackages;
          return {
        themeColor,
        items
          };
        },
    () => ({})
    )(ContentCard));

// TODO: should I use h3's for the card titles?
