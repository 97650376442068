import styled from "styled-components/macro";

export const Title = styled.h3`
    color: #f2284f;
  font-weight: 600;
  margin-bottom: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    
    img{
        margin-left: 20px;
    }
  @media (max-width: 1024px){
    text-align: center;
  }
`

export const PaddingContainer = styled.div`
  padding: 80px 0 50px 0;
  p{
    padding-right: 50px;
  }
  
  @media (max-width: 1024px){
    p{
      padding: 0 20px;
      text-align: center;
    }
  }
  .row-flex{
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
      @media (max-width: 1024px) {
        display: block;
        margin-bottom: 20px;
      }
  }
  @media (min-width: 1920px) {
    width: 1728px!important;
    margin: 0 auto;
  }
`

export const ImageBox = styled.div`
    width: 50%;
    height: 22vw;
    margin-right: 15%;
  @media (min-width: 1280px){
    height: 350px;
  }
    background-color: #333333;
    position: relative;

    @media (max-width: 1024px) {
      width: 100%;
      margin-bottom: 20px;
    }
      .img{
        position: absolute;
        width: 100%;
        padding-bottom: 47%;
        background-size: cover;
        left: 15%;
        bottom: 20%;
        display: inline-block;
        transition: 0.3s background-image;
        @media (max-width: 1024px){
          left: 50%;
          transform: translate(-50%);
          width: 80%;
          padding-bottom: 37%;
        }
      }
`
