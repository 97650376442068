import styled from 'styled-components/macro';


export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.div`
  margin-bottom: 14px;
  width: 338px;
  text-align: center;
`;

export const Subtitle = styled.div`
  width: 333px;
  margin-bottom: 35px;
  text-align: center;
  padding-bottom: 26px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${props => props.theme.colors.lightSilver};
`;

export const Icon = styled.span`
  position: absolute;
  top: 5%;
  font-size: 30px;
  right: 4%;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 240px;
  margin-bottom: 13px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BtnContainer = styled.div`
  width: 110px;
  margin-left: 12px;
`;

export const InputContainer = styled.div`
  width: 342px;
`;
