import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { MODAL_CLOSE } from 'redux/actions';
import {
    Title,
    ContentWrapper,
    ModalWrapper,
    Icon,
    ModalGlobalContainer,
    Header,
    ModalBody,
    ButtonsWrapper,
    DescriptionContent,
    Row, DescriptionText
} from './style';

import {SIGNUP_NEXT_STEP} from "../../../../redux/actions/signup";
import {Button} from "../../../atoms";
import LogoAlphaFcm from "../../../atoms/LogoAlphaFCM/LogoAlphaFCM";


const modalId = 'homepage/SpecializationCreditsEcmModal';


const SpecializationCreditsEcmModal = ({ payload, onClose, nextSignupStep, disciplines, isProvisioner }) => {
    const {ecmProducts} = payload;
    const onContinueHandler = ()=>{
        onClose();
        nextSignupStep(payload.nextStepValue);
    }

    const [filteredGiveEcmCreditsProducts, setFilteredGiveEcmCreditsProducts] = useState([]);
    const [description, setDescription] = useState('')
    useEffect(() => {
        if (!payload.descriptionData) return;
        // descriptionData contiene prodotti e relative specializzazioni che danno crediti ecm


        // se provisioner: visualizzo lista pacchetti/specializzazioni che danno crediti ecm
        if (isProvisioner) {
            setDescription('Ti informiamo che i prodotti selezionati danno crediti ecm solo per le seguenti specializzazioni');
            setFilteredGiveEcmCreditsProducts(ecmProducts?.map(product => {
                const specializations = payload.descriptionData?.find(prodSpec => prodSpec.product_id === product.product_id)?.specializations.data;
                return {
                    variation_id: product.variation_id,
                    variation_title: product.title,
                    specializations
                }
            }))
        }else{
            // Di tutti i prodotti selezionati, verrano mostrati nel popup tutti quelli per cui l'utente non ha una specializzazione tra quelle che danno crediti ecm

            let filteredProducts = null;

            // caso 1: quando l'utente non ha ancora una specializzazione
            if (!disciplines || disciplines.length < 1){
                setDescription('Ti informiamo che per i seguenti prodotti riceverai crediti solamente per le specializzazioni indicate');
            }else{
                // caso 2: quando l'utente ha già una specializzazione
            setDescription('Ti informiamo che per i seguenti prodotti non riceverai crediti ecm');
            }

            // se non provisioner: visualizzo solo i pacchetti (tra quelli selezionati) che non danno crediti ecm in base alla specializzazione
            filteredProducts = ecmProducts?.filter(product => {
                const prodBySpecializ = payload.descriptionData?.find(prodSpec => prodSpec.product_id === product.product_id); // della lista di prodotti-specializzazioni recupero solo quelli che l'utente vuole acquistare
                const hasSpecializWithCreditiEcm = !!prodBySpecializ?.specializations?.data?.find(specialization => {
                    return !!disciplines?.find(discipline => parseInt(discipline.disciplineId) === parseInt(specialization.id)); // se l'utente ha una specializzazione tra quelle del prodotto che danno crediti
                })
                return !prodBySpecializ || !hasSpecializWithCreditiEcm;// se non presente nella lista delle specializzazioni (quindi non da crediti) lo mostro nel popup
            })

            // formatto l'oggetto con titolo prodotto + lista specializzazione che da crediti
            filteredProducts = filteredProducts?.map(product => {
                    const specializations = payload.descriptionData?.find(prodSpec => prodSpec.product_id === product.product_id)?.specializations?.data;
                    return {
                        variation_id: product.variation_id,
                        variation_title: product.title,
                        specializations
                    }
                });
            // se tutti i prodotti danno crediti ecm chiudo popup e vado avanti
            if (!filteredProducts || filteredProducts.length < 1){
                payload.nextStepValue && onContinueHandler();
            }else{
                setFilteredGiveEcmCreditsProducts(filteredProducts);
            }
        }
    }, [ecmProducts, payload, disciplines, isProvisioner]);

    return <Modal id={modalId}>
        <Container>
            <ModalWrapper>
                <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
                    <ContentWrapper>
                        <Header>
                            <Title>
                                {payload.title}
                            </Title>
                            <Icon className="icon-close-x" onClick={onClose} />
                        </Header>
                        <ModalBody>
                            <DescriptionContent>
                                    <DescriptionText>{description}</DescriptionText>
                                    {
                                        (filteredGiveEcmCreditsProducts?.length > 0) && filteredGiveEcmCreditsProducts?.map(packageData => {
                                            return <Row key={packageData.variation_id}>
                                                <h2><strong>{packageData.variation_title}</strong></h2>
                                                {packageData.specializations?.map(specialization => <p key={specialization.id}>{specialization.name}</p>)}
                                            </Row>
                                        })
                                    }
                            </DescriptionContent>
                            <ButtonsWrapper>
                                <Button
                                    text="Annulla"
                                    type="SECONDARY"
                                    height={35}
                                    fontSize={16}
                                    onClick={onClose}
                                />
                                <Button
                                    text="Continua"
                                    type="PRIMARY"
                                    height={35}
                                    fontSize={16}
                                    onClick={onContinueHandler}
                                />
                            </ButtonsWrapper>
                        </ModalBody>
                    </ContentWrapper>
                </ModalGlobalContainer>
            </ModalWrapper>
        </Container>
    </Modal>
};

SpecializationCreditsEcmModal.propTypes = {
    payload: PropTypes.any,
    // HOC (connect, dispatch)
    onClose: PropTypes.func.isRequired,
    nextSignupStep: PropTypes.func.isRequired
};

SpecializationCreditsEcmModal.id = modalId;

export default connect(
    state => {
        const disciplines = state.authentication.user.userDomainAttributes?.jobInfo?.professions?.reduce((mergedDisciplines, profession) => [...mergedDisciplines, ...profession.discipline], []);
        const { userDomainAttributes } = state.authentication.user;
        const isProvisioner = userDomainAttributes?.managerType === 'PROVISIONER';
        return {
            payload: state.modal.payload,
            disciplines,
            isProvisioner
        }
    },
    dispatch => ({
        onClose: () => dispatch({ type: MODAL_CLOSE }),
        nextSignupStep: (nextStep) => dispatch({ type: SIGNUP_NEXT_STEP._REQUEST, nextStep })
    })
)(SpecializationCreditsEcmModal);
