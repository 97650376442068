import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'ui/gridSystem';
import ProText from 'ui/typography/proText';
import { CookiePolicyURL, PrivacyPolicyURL } from 'const';
import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  FooterContainer,
  Copyright,
  CopyrightWrapper,
  Link,
  FooterWrapper,
  LegalLinks,
} from './style';
import LogoAlphaFcm from "../../atoms/LogoAlphaFCM/LogoAlphaFCM";

const Footer = ({ mediaIsPhone }) => (
  <FooterContainer>
    <Container>
      <FooterWrapper>
        <CopyrightWrapper>
          <div>
          <LegalLinks>
            <Link href={CookiePolicyURL} target="_blank">
              <ProText type="1" configuration={{ fontSize: '12' }}>
                COOKIE POLICY
              </ProText>
            </Link>
            <Link href={PrivacyPolicyURL} target="_blank" className="spacer">
              <ProText type="1" configuration={{ fontSize: '12' }}>
                PRIVACY POLICY
              </ProText>
            </Link>
          </LegalLinks>
            <ProText type="1" configuration={{ fontSize: mediaIsPhone ? '10' : '12', color: '#fff' }}>
              <p style={{marginTop: '5px', 'color': '#fff'}}>® Copyright Digit’Ed - P.IVA 07490560633</p>
            </ProText>

          </div>
          <LogoAlphaFcm negative />
          <Copyright>
            <ProText type="1" configuration={{ fontSize: mediaIsPhone ? '10' : '12' }}>
              <p>ALFA FCM Srl</p>
              <p>Provider ECM 3282</p>
                <p>Sede operativa: Via Barnaba Oriani, 114 – 00197 Roma.</p>
                  <p>Sede legale: Viale Mazzini, 6 - 00195 Roma</p>
                    <p>C.F. e P.IVA 11408311006</p>
            </ProText>
          </Copyright>
        </CopyrightWrapper>
      </FooterWrapper>
    </Container>
  </FooterContainer>
);

Footer.propTypes = {
  mediaIsPhone: PropTypes.bool.isRequired,
};

export default withMediaQueries(Footer);
