import styled from 'styled-components/macro';

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

export const Title = styled.div`
  padding-left: 25px;
  @media ${props => props.theme.device.tablet} {
    padding-left: 10px;
  }
`;

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  margin-right: 20px;
  cursor: pointer;
`;

export const BmContainer = styled.div`
  display: flex;
  align-items: center;
  width: 420px;
  min-height: 80px;
  padding: 10px 20px 10px 10px;
  border-bottom: solid 1px ${props => props.theme.colors.brightGray};
  cursor: pointer;

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center top;
`;

export const BmMain = styled.div`
  margin: 0 10px 0 16px;
`;

export const BmTitle = styled.p``;

export const BmDuration = styled.p`
  display: inline-flex;
`;

export const IconPlay = styled.span`
  color: ${props => props.theme.colors.white};
  background-color: #505050;
  width: 13px;
  height: 13px;
  font-size: 8px;
  border-radius: 50%;
  margin-right: 7px;
  padding: 3px;
`;

export const IconClock = styled.span`
  color: ${props => props.theme.colors.slateGray};
  width: 13px;
  height: 13px;
  font-size: 13px;
  margin-right: 7px;
`;

export const IconBookmark = styled.div`
  color: ${props => props.theme.colors.primary.base};
  font-size: 21px;
  margin-left: auto;
  cursor: pointer;
`;

export const EmptyMsg = styled.div`
  width: 100%;
  padding: 10px 20px;
`;
