import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  BillingDataInterface,
  CompanyDataInterface,
  AccountDataInterface
} from 'interface/profile';

import { BILLING_GET_PAYMENT_METHODS } from 'redux/actions/billings';
import { MODAL_OPEN } from 'redux/actions';
import { SECTOR_GET } from 'redux/actions/signup';
import routes from 'routes';

import moment from 'moment';
import { SOURCETYPE } from 'utils/common';
import { priceTotalFormat } from 'utils/price-format';


import { TextField } from 'ui/atoms';
import { CommonCard, Spinner, ProfilePicture, Footer } from 'ui/components';
import ModalResetPassword from 'ui/components/Modals/ModalResetPassword';
import { Container } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import MainContainer from 'pages/style';
import {
  PROFILE_DATA_COMPANY_GET,
  PROFILE_DATA_PAYMENTS_GET,
  PROFILE_SELECTED_DATA_PAYMENT,
  PROFILE_DATA_ACCOUNT_GET,
  PROFILE_GET_DATA_PRIVACY
} from 'redux/actions/profile';
import EditPrivacyModal from 'ui/components/Modals/EditPrivacyModal';
import {
  MyProfileContainer,
  Title,
  CardsContainer,
  CardsWrapper,
  ColMediumTable,
  CardContent,
  ColMedium,
  ColSmall,
  TableBody,
  SmallRow,
  Link
} from 'pages/Profile/style';

const MyProfile = ({
  profile,
  billings,
  company,
  getPaymentMethods,
  mediaIsPhone,
  mediaIsLaptop,
  mediaIsDesktop,
  pushUrl,
  openModal,
  getBillings,
  getSector,
  profileDataAccountGet,
  sector,
  getPrivacyData
}) => {
  useEffect(() => {
    getSector();
    getPrivacyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBillings();
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (profile.userId && !profile.firstname) {
      profileDataAccountGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const getCompanyName = id => {
    if (sector.length) {
      const selectedSector = (sector || []).find(
        item => item.id === company.industryid
      );
      return selectedSector ? selectedSector.name : company.industryid;
    }
    return id;
  };

  //const getDateParsed = (date = moment()) => moment(date).format('DD/MM/YYYY');
  const getDescriptionById = paymentMethod => (
    paymentMethod.type === 'BankTransfer' ? `IBAN termina con ${paymentMethod.paymentAccountNumber?.slice(paymentMethod.paymentAccountNumber.length - 4)}` : paymentMethod.paymentAccountNumber
  );

  return (
    <>
      <MainContainer>
        <Container>
          <MyProfileContainer>
            <Title>
              <ProDisplay
                type="menutitle"
                configuration={mediaIsPhone ? { fontSize: '24' } : null}
              >
                Profilo
              </ProDisplay>
            </Title>
            <CardsContainer>
              <CardsWrapper>
                <CommonCard title="Account" hasHeader>
                  {profile ? (
                    <CardContent>
                      <ColSmall className="profile-pic">
                        <ProfilePicture profile={profile} pictureChange />
                      </ColSmall>
                      <ColMedium className="smaller">
                        <TextField
                          label="Nome"
                          description={profile.firstname}
                          type="text"
                        />
                      </ColMedium>
                      <ColMedium className="smaller-sm">
                        <TextField
                          label="Cognome"
                          description={profile.lastname}
                          type="text"
                        />
                      </ColMedium>
                      <ColMedium>
                        <TextField
                          label="Email"
                          description={profile.email}
                          type="text"
                        />
                      </ColMedium>
                      <ColMedium>
                        <TextField
                          label="Password"
                          description="XXXXXXX"
                          type="hidden"
                          isEditable
                          onClickEdit={() => openModal('ModalResetPassword')}
                        />
                      </ColMedium>
                      {mediaIsDesktop && <ColSmall />}
                      <ColMedium>
                        <Link
                          className="p-0"
                          onClick={() => openModal('EditPrivacyModal')}
                        >
                          <ProDisplay
                            type="link"
                            configuration={{
                              color: 'primary',
                              colorType: 'base'
                            }}
                          >
                            Visualizza contratto e consensi privacy
                          </ProDisplay>
                        </Link>
                      </ColMedium>
                    </CardContent>
                  ) : (
                    <Spinner />
                  )}
                </CommonCard>
                <CommonCard
                  title="Ultimi pagamenti"
                  hasHeader
                  hasTitleIcon
                  onClickIcon={() => pushUrl(routes.editCards.path)}
                  icon="icon-modifica"
                  text="Vedi tutti"
                  onClick={() => pushUrl(routes.lastPayments.path)}
                >
                  {billings ? (
                    <>
                      {(mediaIsDesktop || mediaIsLaptop) && (
                        <>
                          <ColMediumTable>
                            <ProDisplay type="green">Ordine N.</ProDisplay>
                          </ColMediumTable>
                          <ColMediumTable>
                            <ProDisplay type="green">Data Ordine</ProDisplay>
                          </ColMediumTable>
                          <ColMediumTable>
                            <ProDisplay type="green">
                              Totale N. Abbonamenti
                            </ProDisplay>
                          </ColMediumTable>
                          <ColMediumTable>
                            <ProDisplay type="green">
                              Scadenza Abbonamenti
                            </ProDisplay>
                          </ColMediumTable>
                          <ColMediumTable>
                            <ProDisplay type="green">
                              Modalità Pagamento
                            </ProDisplay>
                          </ColMediumTable>
                          <ColMediumTable>
                            <ProDisplay type="green">Stato</ProDisplay>
                          </ColMediumTable>
                          <ColMediumTable>
                            <ProDisplay type="green">Totale Ordine</ProDisplay>
                          </ColMediumTable>
                        </>
                      )}
                      {(billings || [])
                        .filter(
                          b => b.paymentStatus.toLowerCase() !== 'rejected' && b.paymentStatus.toLowerCase() !== 'pending'
                        )
                        .slice(0, 10)
                        .map((item = {}) => (
                          <TableBody key={item.paymentNumber} className="bordered">
                            <CardContent>
                              <ColMediumTable>
                                <TextField
                                  label={
                                    mediaIsDesktop || mediaIsLaptop
                                      ? ''
                                      : 'N. Ordine'
                                  }
                                  description={item.paymentNumber}
                                  type="text"
                                />
                              </ColMediumTable>
                              <ColMediumTable>
                                <TextField
                                  label={
                                    mediaIsDesktop || mediaIsLaptop
                                      ? ''
                                      : 'Data Ordine'
                                  }
                                  description={moment(item.paymentDate).format('DD/MM/YYYY')}
                                  type="text"
                                />
                              </ColMediumTable>
                              <ColMediumTable>
                                {!(mediaIsDesktop || mediaIsLaptop) && (
                                  <TextField
                                    label="N. Abbonamenti"
                                    type="text"
                                  />
                                )}
                                {item?.invoiceItemsList.map((i, idx) => (
                                  <SmallRow key={`order-${idx}`}>
                                    <ProDisplay type="greenTableLabel">
                                      {i.quantity} {i.productName}
                                    </ProDisplay>
                                  </SmallRow>
                                ))}
                              </ColMediumTable>
                              <ColMediumTable>
                                {!(mediaIsDesktop || mediaIsLaptop) && (
                                  <TextField
                                    label="Scadenza Abbonamenti"
                                    description=""
                                    type="text"
                                  />
                                )}
                                {item?.invoiceItemsList.map(i =>
                                  (i.expirationDate ? (
                                    <SmallRow>
                                      <ProDisplay type="greenTableLabel">
                                        {moment(i.expirationDate).format('DD/MM/YYYY')}
                                      </ProDisplay>
                                    </SmallRow>
                                  ) : (
                                    <SmallRow />
                                  )))}
                              </ColMediumTable>

                              <ColMediumTable>
                                <TextField
                                  label={
                                    mediaIsDesktop || mediaIsLaptop
                                      ? ''
                                      : 'Modalità Pagamento'
                                  }
                                  description={getDescriptionById(item.paymentMethod)}
                                  type="text"
                                  hasIcon={item.paymentMethod?.creditCardType?.toLowerCase() || ''}
                                />
                              </ColMediumTable>
                              <ColMediumTable>
                                <TextField
                                  label={
                                    mediaIsDesktop || mediaIsLaptop
                                      ? ''
                                      : 'Stato'
                                  }
                                  description={item?.paymentStatus}
                                  type="text"
                                />
                              </ColMediumTable>
                              <ColMediumTable>
                                <TextField
                                  label={
                                    mediaIsDesktop || mediaIsLaptop
                                      ? ''
                                      : 'Totale Ordine'
                                  }
                                  description={`${priceTotalFormat(item.paymentAmount)} €`}
                                  type="text"
                                />
                              </ColMediumTable>
                            </CardContent>
                          </TableBody>
                        ))}
                    </>
                  ) : (
                    <Spinner />
                  )}
                </CommonCard>
                <CommonCard
                  title="Dati Fatturazione"
                  hasHeader
                  hasIcon={company && company.sourcetype !== SOURCETYPE.BANK}
                  icon="icon-modifica"
                  onClick={() => pushUrl(routes.editData.path)}
                >
                  {company ? (
                    <CardContent>
                      {company.organizationtype !== 'persona_fisica' && <ColMedium>
                        <TextField
                          label="Ragione Sociale"
                          description={company.title || '-'}
                          type="text"
                        />
                      </ColMedium>}
                      {company.organizationtype !== 'persona_fisica' && <ColMedium>
                        <TextField
                          label="P.IVA"
                          description={company.vatnumber || '-'}
                          type="text"
                        />
                      </ColMedium>}
                      <ColMedium>
                        <TextField
                          label="Codice Fiscale"
                          description={company.orgfiscalcode || '-'}
                          type="text"
                        />
                      </ColMedium>
                      <ColMedium className="smaller-sm">
                        <TextField
                          label="Nazione"
                          description="Italia"
                          type="text"
                        />
                      </ColMedium>
                      <ColMedium>
                        <TextField
                          label="Provincia"
                          description={company.district || '-'}
                          type="text"
                        />
                      </ColMedium>
                      <ColMedium className="smaller-sm">
                        <TextField
                          label="Città"
                          description={company.city || '-'}
                          type="text"
                        />
                      </ColMedium>
                      <ColMedium className="smaller-sm">
                        <TextField
                          label="CAP"
                          description={company.zipcode || '-'}
                          type="text"
                        />
                      </ColMedium>
                      <ColMedium className="smaller-sm">
                        <TextField
                          label="Indirizzo"
                          description={company.street || '-'}
                          type="text"
                        />
                      </ColMedium>
                      {company.organizationtype !== 'persona_fisica' && <ColMedium className="smaller-sm">
                        <TextField
                          label="Codice SDI"
                          description={company.sdinumber || '-'}
                          type="text"
                        />
                      </ColMedium>}
                    </CardContent>
                  ) : (
                    <Spinner />
                  )}
                </CommonCard>
                <ModalResetPassword />
              </CardsWrapper>
            </CardsContainer>
          </MyProfileContainer>
        </Container>
      </MainContainer>
      <EditPrivacyModal />
      <Footer />
    </>
  );
};

MyProfile.propTypes = {
  //HOC (connect, dispatch)
  getPaymentMethods: PropTypes.func.isRequired,
  profile: AccountDataInterface,
  billings: BillingDataInterface,
  company: CompanyDataInterface,
  sector: PropTypes.array,
  pushUrl: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  getSector: PropTypes.func.isRequired,
  getBillings: PropTypes.func.isRequired,
  profileDataAccountGet: PropTypes.func.isRequired,

  //HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsLaptop: PropTypes.bool.isRequired,
  mediaIsDesktop: PropTypes.bool.isRequired,
  getPrivacyData: PropTypes.func
};

export default connect(
  state => {
    const { billings: { orders: billings = [] } = {}, company } = state.profile;
    const { user: profile = {} } = state.authentication;
    const { sector } = state.signup;
    return {
      profile,
      billings,
      company,
      sector,
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    openModal: id => dispatch({ type: MODAL_OPEN, id }),
    getPaymentMethods: () =>
      dispatch({
        type: BILLING_GET_PAYMENT_METHODS._REQUEST,
        skipWallet: true
      }),
    getCompany: () => dispatch({ type: PROFILE_DATA_COMPANY_GET._REQUEST }),
    profileDataAccountGet: () =>
      dispatch({ type: PROFILE_DATA_ACCOUNT_GET._REQUEST, toReload: false }),
    getSector: () => dispatch({ type: SECTOR_GET._REQUEST }),
    getBillings: () => dispatch({ type: PROFILE_DATA_PAYMENTS_GET._REQUEST }),
    setSelectedPayment: payment =>
      dispatch({ type: PROFILE_SELECTED_DATA_PAYMENT, payment }),
    getPrivacyData: () => dispatch({ type: PROFILE_GET_DATA_PRIVACY._REQUEST })
  })
)(withMediaQueries(MyProfile));
