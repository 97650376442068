import styled from 'styled-components/macro';

export const SubTitle = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  margin-top: 25px;
  
  @media ${props => props.theme.device.tablet} {
    padding: 0 22px;
  }
`;

export const Labels = styled.div`
  width: 100%;
  padding: 0 30px;
  margin-bottom: 10px;
`;

/*export const TableCardContainer = styled.div`
  &:nth-child(2) {
    border-radius: 0 !important;
  }

  &:first-of-type {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
`;*/

export const Link = styled.a`
  color: ${props => props.theme.colors.primary.base};
  margin-right: 10px;
`;

export const Load = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  cursor: pointer;
  padding-left: 5px;

   &.link-btn{
    min-width: 160px;
    height: 35px;
    color:white;
    text-align: center;
    text-transform: uppercase;
    display:inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background-color: ${props => props.theme.colors.primary.brandColor1};
    * {
    color:white;
    font-weight: 500;
    }
  }

  &:hover {
    & span,
    a {
      color: ${props => props.theme.colors.primary.darker};
    }
  }
`;

export const TableWrapper = styled.div`
  .col-item {
    display: flex;
    align-items: center;

    .mobile-label {
      line-height: 2;
    }

    .mobile-label, .mobile-label-status {
      margin-right: 10px;
    }

    span {
      min-height: 20px;
      display: flex;
      align-items: center;
    }
    
    .button {
      cursor: pointer;
      color: #125C87;

      &.disabled {
        pointer-events: none;
        cursor: none;

        .icon-download {
          color: ${props => props.theme.colors.gray};
        }
      }

      span {
        font-weight: 600;
        font-size: 14px;

        &.icon-icona-play {
          margin-left: 20px;
          font-size: 50px;
          line-height: 0.6;

          @media ${props => props.theme.device.tablet} {
            margin-left: 0px;
          }
        }

        &.icon-download {
          margin-left: 25px;
          font-size: 30px;
          line-height: 0.6;

          @media ${props => props.theme.device.tablet} {
            margin-left: 0px;
          }
        }
      }
    }
  }

  .table-container {
    &:first-of-type {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }

    &:nth-child(2) {
      border-radius: 0;

      @media ${props => props.theme.device.mobile} {
        border-radius: 20px;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BtnContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 100px;

  button {
    background: transparent;
    color: ${props => props.theme.colors.primary.brandColor1};
    padding: 0;
    margin: 0 auto;

    &:hover {
      background-color: transparent ;
    }
  }
`;
