import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { preventBrowserBack } from "utils/common";
import { push } from "redux-first-history";
import { ENV_BASE_API_WP_URL, WP_KEY } from "api/config";
import { setToken } from "utils/token";
import Axios from "axios";
import routes from "routes";
import { AUTHENTICATION_SIGNIN } from "redux/actions/authentication";
import { connect } from "react-redux";
import { AUTH_LOGIN, LOADER_OPEN } from "redux/actions";
import { REMEMBER_ME } from "redux/actions/landingPage";
import {
  PROFILE_DATA_ACCOUNT_GET,
  PROFILE_GET_DATA_PRIVACY,
} from "redux/actions/profile";
import { SIGNUP_NEXT_STEP } from "redux/actions/signup";

preventBrowserBack();

const ExternalLogin = ({ authenticationSignin, pushUrl, isLogged }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [hasError, setHasError] = useState(false);

  const otpValue = searchParams.get("otp");
  const productIdValue = searchParams.get("product_id");

  useEffect(() => {
    if (!isLogged && !hasError) {
      if (!otpValue || !productIdValue) {
        pushUrl(routes.access.path);
      } else {
        getWPToken(otpValue);
      }
    }
  }, [isLogged, hasError]);

  const getWPToken = async (otpValue) => {
    const baseUrl =
      ENV_BASE_API_WP_URL[process.env.REACT_APP_DEPLOY_ENV] ||
      ENV_BASE_API_WP_URL.DEFAULT;

    try {
      let response;

      if (
        process.env.REACT_APP_DEPLOY_ENV == "SIT" || process.env.REACT_APP_DEPLOY_ENV == "PRE_PROD" 
      ) {
        const username = WP_KEY.username;
        const password = WP_KEY.password;
        const credentials = btoa(`${username}:${password}`);

        response = await Axios.get(
          `${baseUrl}/wp-json/api/v1/get-user-tokens-by-otp?otp=${otpValue}`,
          {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        );
      } else {
        response = await Axios.get(
          `${baseUrl}/wp-json/api/v1/get-user-tokens-by-otp?otp=${otpValue}`
        );
      }

      const { data } = response;

      const dataAuth = {
        userId: data.userName,
        firstname: data.givenName,
        lastname: data.familyName,
        email: data.email,
        role: data.role,
        pictureId: "",
        productToActivate: productIdValue,
        organizationId: data.organizationId,
        prospectId: "",
        clientEnabled: data.clientEnabled,
        domainList: data.domainList,
        imageUrl: null,
        fiscalCode: data.cf,
        phoneNumber: data.phoneNumber,
        imageUrl: "",
        sendDataTreatment: data.sendDataTreatment,
      };

      const dataToken = {
        token: data.token,
        accesstoken: data.accessToken,
        refreshtoken: data.refreshToken,
        expiresin: data.expiresIn,
        userid: data.userName,
      };

      authenticationSignin(dataToken, dataAuth);
    } catch (error) {
      setHasError(true);
      pushUrl(routes.access.path);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h3>Redirect in corso...</h3>
    </div>
  );
};

ExternalLogin.propTypes = {
  pushUrl: PropTypes.func,
  authenticationSignin: PropTypes.func,
  authenticationLogin: PropTypes.func,
  rememberMe: PropTypes.func,
  setFirstAccess: PropTypes.func,
};

export default connect(
  (state) => ({
    domainId: state.domain,
    isLogged: state.app.authState.isLogged,
    subscriptions_config_success: state.configuration,
    delete_subscription: state.configuration,
    welcome: state.configuration,
    clientEnabled: state.authentication.user.clientEnabled,
    sendDataTreatment: state.authentication.user.sendDataTreatment,
    domain: state.domain,
    router: state.router,
    isFirstAccess: state.landingPage,
    state: state,
  }),
  (dispatch) => ({
    authenticationSignin: (dataToken, dataAuth) => {
      dispatch({ type: LOADER_OPEN });

      setToken(dataToken);

      if (!dataAuth.organizationid && dataAuth.senddatatreatment) {
        dispatch({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 3 });
        dispatch(push(routes.billings.path));
      }

      dispatch({
        type: AUTHENTICATION_SIGNIN._SUCCESS,
        user: dataAuth,
        clientEnabled: dataAuth.clientEnabled,
      });

      if (!dataAuth.sendDataTreatment) {
        dispatch({ type: PROFILE_DATA_ACCOUNT_GET._REQUEST });
      }
      if (dataAuth.organizationId || !dataAuth.sendDataTreatment) {
        dispatch({ type: AUTH_LOGIN._SUCCESS });
      }
      dispatch({ type: PROFILE_GET_DATA_PRIVACY._REQUEST });
    },

    rememberMe: (remember) => dispatch({ type: REMEMBER_ME, remember }),
    pushUrl: (url) => dispatch(push(url)),
  })
)(ExternalLogin);
