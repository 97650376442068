import styled, { css } from 'styled-components/macro';

export const LogoAlphaFcmImg = styled.img `
    width: 100px;
    height: 45px;
    margin: 0 auto;

    &.right{
        margin: 0;
    }
    
    &.negative{
        width: 108px;
        height: 44px;
    }
    
    &.inline{
        width: 60px;
        height: 27px;
        margin: 5px;
    }
`
